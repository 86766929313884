<template>
  <div>
    <!--body content start-->

    <!--about start-->

    <section class="position-relative overflow-hidden mt-5">
      <div class="pattern-3">
        <img class="img-fluid rotateme" src="/images/pattern/03.png" alt="" />
      </div>
      <div class="container">
        <div class="section-title mb-2">
          <h2 class="title">About Us</h2>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12">
            <p>We believe there is a better way to do marketing. A more valuable way for customers to be acquired and not purchased. We are passionate about what we do and our mission is to help companies achieve this. We focus on customer retention and absorption. This is one of the least understood and least transparent aspects of good marketing, and we see it as an opportunity. We are excited to make customer interactions easier for everyone through our soft solutions.</p>
          </div>
          <div class="col-lg-7 col-md-12 mt-5 mt-lg-0">
            <div class="item">
              <div class="featured-item text-center style-2 mx-3 my-3">
                <div class="featured-icon">
                  <img class="img-fluid" src="/images/feature/01.png" alt="" />
                </div>
                <div class="featured-title">
                  <h5>Bulk SMS</h5>
                </div>
                <div class="featured-desc">
                  <p>
                    Sharing of timely personalized mass text messaging.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--about end-->

    <section class="o-hidden grey-bg mt-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="info-img pos-r">
                <img
                  class="img-fluid topBottom"
                  src="/default/02.png"
                  alt="image"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 md-mt-5">
              <div class="section-title mb-4">
                <h4 class="title">Our Vision</h4>
                <p>To be the leading customer-centric, cost-effective customer engagement platform in the world.</p>
                <h4 class="title">Our Mission</h4>
                <p>We exist to bring quality, real time, secure, cost-effective and futuristic soft solutions to businesses as we adapt to the changing technology.</p>
                <h4 class="title">Core Values</h4>
                <p>Integrity - Guided by ethical and ethical principles in all our decisions to advance the interests of those we represent</p>
                <p>Teamwork - Collaborative approach to the public the work and efforts of our team and customers, regardless of our ability to work together. </p>
                <p>Responsibility - Our concept of reliability extends beyond our solutions as it includes; our delivery mechanism. We are trustworthy and we provide reliable results</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    <!--service start-->

    <section
      class="position-relative overflow-hidden"
      data-bg-img="/images/pattern/01.png"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-6">
            <div class="section-title mb-0">
              <h6>Our Services</h6>
              <h2 class="title">We have best features</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
            <div class="featured-item text-center">
              <div class="featured-icon">
                <img class="img-fluid" src="/images/feature/04.png" alt="" />
              </div>
              <div class="featured-title">
                <h5>Bulk SMS</h5>
              </div>
              <div class="featured-desc">
                <p>
                  Sharing of timely personalized mass text messaging.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
            <div class="featured-item text-center">
              <div class="featured-icon">
                <img class="img-fluid" src="/images/feature/05.png" alt="" />
              </div>
              <div class="featured-title">
                <h5>USSD</h5>
              </div>
              <div class="featured-desc">
                <p>
                  Get real time feedback by sending text menus for data ...
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt-5">
            <div class="featured-item text-center">
              <div class="featured-icon">
                <img class="img-fluid" src="/images/feature/06.png" alt="" />
              </div>
              <div class="featured-title">
                <h5>Voice</h5>
              </div>
              <div class="featured-desc">
                <p>
                  Handle multiple incoming and outgoing calls from ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--service end-->

    <!--client logo start-->

    <clients />

    <!--client logo end-->

    <!--body content end-->
  </div>
</template>

<script>
import Clients from '@/components/Clients'

export default {
  components: {
    Clients
  },

  data() {
    return {
      show: 1
    }
  }
};
</script>
