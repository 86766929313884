<template>
  <section class="pt-0">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title mb-0">
            <h6>Our Clients</h6>
          </div>
          <div class="ht-clients d-flex flex-wrap align-items-center text-center slider">
            <ul class="client-width">
              <li class="clients-logo">
                <img class="img-center" src="/clients/odpp.jpg" alt="odpp" />
              </li>
              <li class="clients-logo">
                <img class="img-center" src="/clients/safegate.png" alt="safegate" />
              </li>
              <li class="clients-logo">
                <img class="img-center" src="/clients/endatrip.jpg" alt="endatrip" />
              </li>
              <li class="clients-logo">
                <img class="img-center" src="/clients/oaknet.png" alt="oaknet" />
              </li>
              <li class="clients-logo">
                <img class="img-center mtenders" src="/clients/mtenders-africa.png" alt="mtenders-africa" />
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
